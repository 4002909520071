/*  */
.filterbtn {
  min-width: 70px;
  height: calc(1.5em + 1rem + 2px);
  margin-left: 20px;
  margin-right: 20px;
  font-size: 0.875rem;
  background-color: rgb(48, 125, 239);
  color: #fff;
  border-radius: 0.3rem;
  border: 1px solid #e2e7f1;
}

.bookinglist {
  font-size: 19px;
  color: #4B4B5A;
  margin-left: 10px;
}

.editorstyle {
  border: 1px solid #e2e7f1;
  min-height: 100px;
  border-radius: 5px;
  margin-left: 10px;
}
.common-btn-width{
  width: 100px;
}