// 
// calendar.scss
//
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/bootstrap/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';

.calendar {
    float: left;
    margin-bottom: 0;
}

.fc-view {
    margin-top: 30px;
}

.none-border {
    .modal-footer {
        border-top: none;
    }
}

.fc-toolbar {
    margin: 10px 0 5px 0;

    h2 {
        font-size: 1.25rem;
        line-height: 1.875rem;
        text-transform: uppercase;
    }
}

.fc-day-grid-event {
    .fc-time {
        font-weight: $font-weight-medium;
    }
}

th.fc-day-header {
    padding: 0.5rem 0;
}

.fc-day {
    background: transparent;
}

.fc-toolbar {

    .fc-state-active,
    .ui-state-active,
    button:focus,
    button:hover,
    .ui-state-hover {
        z-index: 0;
    }
}

.fc {
    th.fc-widget-header {
        background: $gray-300;
        font-size: 13px;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
    }
}

.fc-unthemed {

    th,
    td,
    thead,
    tbody,
    .fc-divider,
    .fc-row,
    .fc-popover {
        border-color: $gray-300;
    }

    td.fc-today,
    .fc-divider {
        background: $gray-300;
    }
}


.fc-button {
    background: $gray-300;
    border: none;
    color: $gray-700;
    text-transform: capitalize;
    box-shadow: none;
    border-radius: 3px;
    margin: 0 3px;
    padding: 6px 12px;
    height: auto;
}

.fc-text-arrow {
    font-family: inherit;
    font-size: 1rem;
}

.fc-state-hover {
    background: $gray-300;
}

.fc-state-highlight {
    background: $gray-300;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-cell-overlay {
    background: $gray-300;
}

.fc-unthemed {
    .fc-today {
        background: $white;
    }
}

.fc-event {
    border-radius: 2px !important;
    border: none !important;
    border-color: transparent !important;
    cursor: move;
    font-size: 0.8125rem !important;
    margin: 5px 7px !important;
    padding: 5px 5px !important;
    text-align: center !important;
}

.external-event {
    cursor: move;
    margin: 10px 0;
    padding: 8px 10px;
    color: $white;
    border-radius: 4px;
}

.fc-basic-view {
    td.fc-week-number {
        span {
            padding-right: 8px;
        }
    }

    td.fc-day-number {
        padding-right: 8px;
    }

    .fc-content {
        color: $white;
    }
}

.fc-time-grid-event {
    .fc-content {
        color: $white;
    }
}

@include media-breakpoint-down(sm) {
    .fc-toolbar {
        display: block;

        .fc-left,
        .fc-right,
        .fc-center {
            float: none;
            display: block;
            clear: both;
            margin: 10px 0;
        }
    }

    .fc {
        .fc-toolbar {
            >* {
                >* {
                    float: none;
                }
            }
        }
    }

    .fc-today-button {
        display: none;
    }
}

// overriding button sizes
.fc-toolbar {
    .btn {
        @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    }
}