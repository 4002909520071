//
// datatable.scss
//


.react-bootstrap-table-pagination-list .pagination {
    justify-content: flex-end;
}

.react-bootstrap-table {
    table {
        table-layout: auto;
    }
}

@include media-breakpoint-down(sm) {
    .react-bootstrap-table-pagination-list .pagination {
        margin-top: 10px;
        justify-content: flex-start;
    }
}

.react-bs-table-sizePerPage-dropdown {
    .btn {
        padding: 0.28rem 0.8rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.15rem;
    }
}